import { FilterOutlined, LeftOutlined } from "@ant-design/icons";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import { Button, Checkbox } from "antd";
import { useCallback, useMemo, useState } from "react";
import { HorizontalSeparator } from "../../tabs/members/create/AddMember";
import { useFilterContext } from "./FilterContext";

export const FilterButton = ({ onSubmit }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  return (
    <Tippy
      content={
        <FilterContent
          setIsDropdownVisible={setIsDropdownVisible}
          onSubmit={onSubmit}
        />
      }
      interactive
      arrow={false}
      trigger="click"
      placement="bottom"
      hideOnClick
      visible={isDropdownVisible}
      onClickOutside={() => setIsDropdownVisible(false)}
      className="bg-white text-black"
    >
      <div>
        <Button
          onClick={() => setIsDropdownVisible(true)}
          icon={<FilterOutlined />}
        />
      </div>
    </Tippy>
  );
};

const FilterContent = ({ setIsDropdownVisible, onSubmit }) => {
  const {
    selectedFilter,
    setSelectedFilter,
    setAppliedFilters,
    handleSubmit,

    appliedFilters,
  } = useFilterContext();

  return (
    <div className="flex flex-col min-w-[300px]">
      <div className="flex gap-3 items-center">
        {selectedFilter && (
          <div
            className="h-7 w-7 rounded-full bg-gray-100 flex items-center justify-center p-2 cursor-pointer"
            onClick={() => setSelectedFilter(undefined)}
          >
            <LeftOutlined />
          </div>
        )}
        <div className="flex p-2 text-xl font-bold">Select Filters</div>
      </div>
      <HorizontalSeparator className="mb-2 -mx-[8px]" />
      <FiltersList />
      <HorizontalSeparator className="my-2 -mx-[8px]" />
      <Button
        className="w-full my-3"
        onClick={() => [onSubmit(appliedFilters), setIsDropdownVisible(false)]}
        type="primary"
      >
        Submit
      </Button>
      <div className="flex gap-3 w-full">
        <Button className="w-full" onClick={() => setIsDropdownVisible(false)}>
          Close
        </Button>
        <Button
          className="w-full"
          onClick={() => [
            onSubmit(),
            setIsDropdownVisible(false),
            setAppliedFilters({}),
            setSelectedFilter(undefined),
          ]}
        >
          Clear All Filters
        </Button>
      </div>
    </div>
  );
};

const FiltersList = () => {
  const { setSelectedFilter, selectedFilter, filterOptions } =
    useFilterContext();

  if (selectedFilter) {
    return <FilterSubOptions />;
  }
  return (
    <div className="flex flex-col">
      {filterOptions.map((item, index) => {
        return (
          <div
            key={index}
            className="flex justify-between p-2 items-center cursor-pointer hover:bg-slate-50 rounded-md"
            onClick={() => setSelectedFilter(item.value)}
          >
            <div key={index} className="leading-6">
              {item.label}
            </div>
            <ArrowRightIcon className="h-4 w-4" />
          </div>
        );
      })}
    </div>
  );
};

const FilterSubOptions = () => {
  const { selectedFilter, appliedFilters, setAppliedFilters, filterOptions } =
    useFilterContext();
  const selectedFilterData = useMemo(
    () => filterOptions.find((item) => item.value === selectedFilter),
    [filterOptions, selectedFilter]
  );

  const handleOptionSelect = useCallback(
    (value) => {
      // console.log("line 118 ", value);
      if (!selectedFilterData) return;
      setAppliedFilters((prev) => {
        const existingData = prev[selectedFilterData.value];

        if (!existingData) {
          return { ...prev, [selectedFilterData.value]: [value] };
        }

        if (existingData.includes(value)) {
          return {
            ...prev,
            [selectedFilterData.value]: existingData.filter(
              (item) => item !== value
            ),
          };
        }

        return {
          ...prev,
          [selectedFilterData.value]: [...existingData, value],
        };
      });
    },
    [selectedFilterData, setAppliedFilters]
  );

  const selectedOptions = useMemo(
    () => appliedFilters[selectedFilterData?.value],
    [appliedFilters, selectedFilterData?.value]
  );

  return (
    <div className="flex flex-col">
      {selectedFilterData.options.map((item, index) => {
        const isSelected = selectedOptions?.includes(item.value);

        return (
          <div
            key={index}
            className="flex justify-between p-2 items-center cursor-pointer hover:bg-slate-50 rounded-md"
            onClick={() => handleOptionSelect(item.value)}
          >
            <div key={index} className="leading-6">
              {item.label}
            </div>
            <Checkbox checked={isSelected} />
          </div>
        );
      })}
    </div>
  );
};
