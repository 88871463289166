import { Select } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveGymId } from "../../redux/actions/app";
import { getActiveGymId, getGymsList } from "../../redux/selectors/app";

const GymSelector = () => {
  const dispatch = useDispatch();
  const gymList = useSelector(getGymsList);
  const activeGymId = useSelector(getActiveGymId);

  const mappedGym = useMemo(() => {
    return gymList.map((item) => ({ label: item.name, value: item.id }));
  }, [gymList]);

  return (
    <Select
      placeholder="Select a Gym"
      options={mappedGym}
      value={activeGymId}
      onChange={(value) => {
        dispatch(setActiveGymId(value));
        localStorage.setItem("gymId", value);
      }}
    />
  );
};

export default GymSelector;
