import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import {
  getGymListApi,
  getUserInfo,
  updateUserPlansStatus,
} from "../../tabs/auth/api-clients";
import {
  GET_USER_INFO,
  GYM_LIST_REQUEST_SUCCEED,
  INITIALIZE,
  SET_ACTIVE_GYM,
  USER_INFO_REQUEST_SUCCEED,
  gymListRequest,
  gymListRequestFailed,
  gymListRequestSucceed,
  setActiveGymId,
  setAppInitialized,
  setOnBoardingModalStatus,
  updateUserPlanRequest,
  updateUserPlanRequestFailed,
  updateUserPlanRequestSucceed,
  userInfoRequest,
  userInfoRequestFailed,
  userInfoRequestSucceed,
} from "../actions/app";
import {
  getActiveGymId,
  getGymsList,
  isOnboardingModalOpen,
} from "../selectors/app";
import { history } from "../store/history";

export function* userSaga() {
  yield all([
    takeLatest("HELLO_WORLD", handleHelloWorld),
    takeLatest(INITIALIZE, handleInitialization),
    takeLatest(SET_ACTIVE_GYM, handlesetActiveGym),
    takeLatest(GET_USER_INFO, handleGetUserInfo),
  ]);
}

function handleHelloWorld() {
  console.log("Hello World action - received");
}

function* handleInitialization() {
  // yield fork(handleUpdateUserPlan);
  //app will be initialized here
  yield fork(handleGetUserInfo);
  yield take(USER_INFO_REQUEST_SUCCEED);
  yield fork(handleGetGymList);

  //check for handleGetGymList API to succeed
  yield take(GYM_LIST_REQUEST_SUCCEED);

  const activeGymId = yield select(getActiveGymId);
  const gyms = yield select(getGymsList);
  if (gyms.length === 0) {
    // history.push(`/0000-0000-0000-0000/gym/info`);
    //history.push(`/onboarding`);
    yield put(setOnBoardingModalStatus(true));
  } else {
    const localStorageId = localStorage.getItem("gymId");
    const firstGymId = localStorageId || gyms[0].id;

    yield put(setActiveGymId(firstGymId));
  }

  yield put(setAppInitialized());
}

function* handleGetUserInfo() {
  try {
    yield put(userInfoRequest());
    const res = yield call(getUserInfo);
    yield put(userInfoRequestSucceed(res.data));
  } catch (err) {
    yield put(userInfoRequestFailed(err));
  }
}

function* handleUpdateUserPlan() {
  try {
    yield put(updateUserPlanRequest());
    const res = yield call(updateUserPlansStatus);
    yield put(updateUserPlanRequestSucceed(res.data));
  } catch (err) {
    yield put(updateUserPlanRequestFailed(err));
  }
}

function* handleGetGymList() {
  try {
    yield put(gymListRequest());
    const res = yield call(getGymListApi);
    yield put(gymListRequestSucceed(res.data));
  } catch (err) {
    yield put(gymListRequestFailed(err));
  }
}

function* handlesetActiveGym({ payload }) {
  try {
    yield fork(handleGetGymList);
    yield put(setActiveGymId(payload));
    //check for handleGetGymList API to succeed
    yield take(GYM_LIST_REQUEST_SUCCEED);

    //check if onboarding modal is open
    const onboardingModalStatus = yield select(isOnboardingModalOpen);
    if (onboardingModalStatus) {
      yield put(setOnBoardingModalStatus(false));
    }

    history.replace(`/`);
  } catch (err) {
    yield put(gymListRequestFailed(err));
  }
}
