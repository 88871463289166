import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { mapperDayWise } from "../../../utils/helpers";
import {
  useAddRoutineExercise,
  useGetAddedExercises,
  useRemoveAddedRoutineExercise,
} from "./workout-routine-apiClient";

export const CreateWorkoutRoutineStep = {
  CREATE_NAME: "createName",
  WORKOUT_DAY: "workoutDay",
  ADD_EXERCISE: "addExercise",
};

const CreateWorkoutRoutineContext = createContext({});

const CreateWorkoutRoutineContextProvider = ({ children }) => {
  const { dayId, routineId } = useParams();

  const {
    inProgress: isAddExerciseInProgress,
    execute: addExercise,
    isSuccess: isAddExerciseSuccedded,
  } = useAddRoutineExercise();

  const { data: addedExercises, mutate: mutateAddedExercises } =
    useGetAddedExercises(Number(routineId));

  const {
    execute: deleteExerciseFromRoutine,
    isSuccess: isRemovedExerciseSuccess,
    inProgress: isRemoveInProgress,
  } = useRemoveAddedRoutineExercise();

  //this state is to set the current selected exercise to show the data in SetsRepsModal
  const [currentSelectedExercise, setCurrentSelectedExercise] =
    useState(undefined);

  const addedExercisesData = useMemo(
    () => addedExercises?.data ?? [],
    [addedExercises?.data]
  );

  useEffect(() => {
    if (isAddExerciseSuccedded || isRemovedExerciseSuccess) {
      mutateAddedExercises();
    }
  }, [isAddExerciseSuccedded, isRemovedExerciseSuccess, mutateAddedExercises]);

  // useEffect(() => {
  //   if (isRemovedExerciseSuccess) {
  //     mutateAddedExercises();
  //   }
  // }, [isRemovedExerciseSuccess, mutateAddedExercises]);

  const mappedDayWiseData = useMemo(() => {
    return mapperDayWise(addedExercisesData);
  }, [addedExercisesData]);

  const daysWhichHasExercisesAdded = useMemo(() => {
    return [...new Set(addedExercisesData.map((item) => item.dayId))]; // [ 'A', 'B']
  }, [addedExercisesData]);

  return (
    <CreateWorkoutRoutineContext.Provider
      value={{
        currentSelectedExercise,
        setCurrentSelectedExercise,
        isAddExerciseInProgress,
        addExercise,
        addedExercisesData,
        deleteExerciseFromRoutine,
        daysWhichHasExercisesAdded,
        mappedDayWiseData,
        mutateAddedExercises,
        isRemoveInProgress,
      }}
    >
      {children}
    </CreateWorkoutRoutineContext.Provider>
  );
};

export default memo(CreateWorkoutRoutineContextProvider);

export const useCreateWorkoutRoutineContext = () => {
  return useContext(CreateWorkoutRoutineContext);
};
