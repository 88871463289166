import { useCallback } from "react";
import useFetchAsync from "../../hooks/useFetchAsync";
import { get, post } from "../../http-clients/clients";

export const login = (email, password) => {
  return post("/users/login", { email, password });
};

export const useLogin = () => {
  return useFetchAsync((email, password) =>
    post("/gym/login", { email, password })
  );
};

export const useForgotPassword = () => {
  return useFetchAsync((email) => post("/gym/forgot-password", { email }));
};

export const useResetPassword = () => {
  return useFetchAsync((password, token) =>
    post("/gym/reset-password", { password, token })
  );
};

export const register = (name, email, password) => {
  return post("/users/register", { name, email, password });
};

export const useRegister = () => {
  return useFetchAsync((name, email, password) =>
    post("/gym/register", { name, email, password })
  );
};

export function useVerifyRegisterUser() {
  const createUser = useCallback((data) => {
    return post(`/gym/verify-register-otp`, data);
  }, []);
  return useFetchAsync(createUser);
}

export const getUserInfo = () => {
  return get("/gym/admin-info");
};

export const updateUserPlansStatus = () => {
  return get("/gym/handle-expired-plans");
};

export const getGymListApi = () => {
  return get("/gym/admin-gym-list");
};

export const useActivateAccount = () => {
  return useFetchAsync((token) => post("/gym/activate", { token }));
};
