import { ReloadOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Button, DatePicker } from "antd";
import Search from "antd/es/input/Search";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGlobalFilters } from "../../redux/actions/app";
import { getGlobalFilters } from "../../redux/selectors/app";
import { FilterButton } from "../filters/FilterButton";

const { RangePicker } = DatePicker;

const TabHeader = ({
  onButtonClick,
  isLoading,
  label,
  buttonLabel = "Submit",
  showBackBtn = false,
  onReload,
  showCalendar = false,
  onSearch,
  filters = {},
}) => {
  const dispatch = useDispatch();
  const globalFilters = useSelector(getGlobalFilters);

  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between gap-3 flex-col sm:flex-row">
      <div className="flex items-center gap-3 w-full text-left">
        {showBackBtn && (
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-white cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon />
          </div>
        )}
        <span className="text-lg font-semibold">{label}</span>
      </div>
      <div className="flex gap-3 w-full justify-end">
        {onSearch && (
          <Search
            placeholder="Search Name Or Phone Or Email"
            onSearch={onSearch}
            style={{
              maxWidth: 300,
            }}
            className="w-full"
          />
        )}
        {Object.keys(filters).length > 0 && (
          <FilterButton onSubmit={filters.onSubmit} />
        )}
        {onReload && (
          <Button
            type="primary"
            // shape="circle"
            icon={<ReloadOutlined />}
            onClick={onReload}
            allowClear
            enterButton
            className="shrink-0"
          />
        )}

        {onButtonClick && (
          <Button type="primary" onClick={onButtonClick} loading={isLoading}>
            {buttonLabel}
          </Button>
        )}
        {showCalendar && (
          <RangePicker
            picker="year"
            id={{
              start: "startInput",
              end: "endInput",
            }}
            // onFocus={(_, info) => {
            //   console.log("Focus:", info.range);
            // }}
            // onBlur={(_, info) => {
            //   console.log("Blur:", info.range);
            // }}
            onChange={(value) => {
              dispatch(
                setGlobalFilters({
                  startDate: dayjs(value[0]).format("YYYY"),
                  endDate: dayjs(value[1]).format("YYYY"),
                })
              );
            }}
            value={[
              dayjs().year(globalFilters.startDate),
              dayjs().year(globalFilters.endDate),
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default TabHeader;
