import { Button } from "antd";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "../../../components/inputs/Input";
import { useNotification } from "../../../components/notification/NotificationContext";
import { useCreateWrokoutRoutineName } from "./workout-routine-apiClient";

const FORM_FIELDS = {
  NAME: "name",
};

const AddRoutineName = () => {
  const navigate = useNavigate();
  const { errorNotification } = useNotification();
  const {
    inProgress,
    execute: handleExecution,
    isSuccess,
    error,
    data,
  } = useCreateWrokoutRoutineName();
  const { values, setFieldValue, handleChange, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        [FORM_FIELDS.NAME]: "",
      },
      validationSchema: Yup.object({
        [FORM_FIELDS.NAME]: Yup.string().required("This field is Required."),
      }),
      onSubmit: (values) => {
        handleExecution(values);
      },
    });

  useEffect(() => {
    if (error) {
      errorNotification();
    }
  }, [error, errorNotification]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workout-day/${data.id}`);
    }
  }, [data?.id, isSuccess, navigate]);

  return (
    <div className="flex flex-col w-full">
      <form className="flex flex-col w-full gap-5">
        <p>
          <span className="font-bold">Instruction:</span> Add a Routine
          name...this can be anything it's just to remember the Routine.
        </p>
        <Input
          id={FORM_FIELDS.NAME}
          label="Enter Workout Routine Name"
          name={FORM_FIELDS.NAME}
          value={values[FORM_FIELDS.NAME]}
          error={errors[FORM_FIELDS.NAME]}
          onChange={handleChange}
        />
        <Button
          loading={inProgress}
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddRoutineName;
