export const HELLO_WORLD = "HELLO_WORLD";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const INITIALIZE = "INITIALIZE";
export const SET_ACTIVE_GYM_ID = "SET_ACTIVE_GYM_ID";
export const SET_ONBOARDING_MODAL_STATUS = "SET_ONBOARDING_MODAL_STATUS";
export const SET_ACTIVE_GYM = "SET_ACTIVE_GYM";
export const GET_USER_INFO = "GET_USER_INFO";

export const SET_GLOBAL_FILTERS = "SET_GLOBAL_FILTERS";
export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";

export const setAppInitialized = (payload) => ({
  type: SET_APP_INITIALIZED,
});

export const helloWorld = (payload) => ({ type: HELLO_WORLD, payload });
export const setActiveTab = (payload) => ({ type: SET_ACTIVE_TAB, payload });
export const initialize = () => ({ type: INITIALIZE });
export const setActiveGymId = (payload) => ({
  type: SET_ACTIVE_GYM_ID,
  payload,
});
export const setOnBoardingModalStatus = (payload) => ({
  type: SET_ONBOARDING_MODAL_STATUS,
  payload,
});
export const setActiveGym = (payload) => ({
  type: SET_ACTIVE_GYM,
  payload,
});

export const setGlobalFilters = (payload) => ({
  type: SET_GLOBAL_FILTERS,
  payload,
});

//USER INFO ACTIONS
export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_REQUEST_SUCCEED = "USER_INFO_REQUEST_SUCCEED";
export const USER_INFO_REQUEST_FAILED = "USER_INFO_REQUEST_FAILED";

export const userInfoRequest = () => ({ type: USER_INFO_REQUEST });
export const userInfoRequestSucceed = (payload) => ({
  type: USER_INFO_REQUEST_SUCCEED,
  payload,
});
export const userInfoRequestFailed = (payload) => ({
  type: USER_INFO_REQUEST_FAILED,
  payload,
});

//Member Plans ACTIONS
export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST";
export const UPDATE_USER_INFO_REQUEST_SUCCEED =
  "UPDATE_USER_INFO_REQUEST_SUCCEED";
export const UPDATE_USER_INFO_REQUEST_FAILED =
  "UPDATE_USER_INFO_REQUEST_FAILED";

export const updateUserPlanRequest = () => ({ type: UPDATE_USER_INFO_REQUEST });
export const updateUserPlanRequestSucceed = (payload) => ({
  type: UPDATE_USER_INFO_REQUEST_SUCCEED,
  payload,
});
export const updateUserPlanRequestFailed = (payload) => ({
  type: UPDATE_USER_INFO_REQUEST_FAILED,
  payload,
});

//USER GYM LIST ACTIONS
export const GYM_LIST_REQUEST = "GYM_LIST_REQUEST";
export const GYM_LIST_REQUEST_SUCCEED = "GYM_LIST_REQUEST_SUCCEED";
export const GYM_LIST_REQUEST_FAILED = "GYM_LIST_REQUEST_FAILED";

export const gymListRequest = () => ({ type: GYM_LIST_REQUEST });
export const gymListRequestSucceed = (payload) => ({
  type: GYM_LIST_REQUEST_SUCCEED,
  payload,
});
export const gymListRequestFailed = (payload) => ({
  type: GYM_LIST_REQUEST_FAILED,
  payload,
});

export const getUserInfo = () => ({ type: GET_USER_INFO });
