import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../http-clients/clients";
import { getActiveGymId } from "../../redux/selectors/app";
import { addQueryParametersToUrl } from "../../utils/helpers";

// export const useAddGymMember = () => {
//   const activeGymId = useSelector(getActiveGymId);
//   const addMember = useCallback(
//     (data) => {
//       return post("/gym/member/create", { ...data, gymId: activeGymId });
//     },
//     [activeGymId]
//   );

//   return useFetchAsync(addMember);
// };

export const useAddGymMember = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return post("/gym/staff/create", {
        ...data,
        gymId: activeGymId,
        type: 2,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useGetGymMembers = ({ searchText, filters = {} }) => {
  const activeGymId = useSelector(getActiveGymId);
  const allFilters = { ...filters, search: searchText };
  return useSWR(
    `/gym/members/${activeGymId}${addQueryParametersToUrl(allFilters)}`,
    get
  );
};

// export const useDeleteGymMember = () => {
//   const activeGymId = useSelector(getActiveGymId);
//   const addMember = useCallback(
//     (data) => {
//       return deleteReq(`/gym/member/${activeGymId}`, { ...data });
//     },
//     [activeGymId]
//   );

//   return useFetchAsync(addMember);
// };

export const useDeleteGymMember = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return deleteReq(`/gym/staff/${activeGymId}`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

// export const useGetGymMember = (memberId) => {
//   return useSWR(memberId ? `/gym/member/${memberId}` : null, get);
// };

export const useGetGymMember = (staffId) => {
  const activeGymId = useSelector(getActiveGymId);
  return useSWR(
    staffId ? `/gym/staff/${activeGymId}/member/${staffId}` : null,
    get
  );
};

export const useUpdateGymMember = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/staff/member`, {
        ...data,
        gymId: activeGymId,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useUpdateMemberStatus = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (status) => {
      return patch(`/gym/member/update`, {
        status,
        gymId: activeGymId,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export function useAssignWorkoutTemplate() {
  const loginUser = useCallback((data) => {
    return post(`/workout-routine/assign-to-me`, data);
  }, []);
  return useFetchAsync(loginUser);
}
