import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import "./App.css";
import HorizontalLayout from "./components/layouts/HorizontalLayout";
import { initialize } from "./redux/actions/app";
import {
  getActiveGymId,
  getUserRoleForActiveGym,
  hasGymsAdded,
  isAppInitialized,
  isGymInfoInProgress,
  isGymListApiInProgress,
} from "./redux/selectors/app";
import AdminRoutes from "./routes/AdminRoutes";
import StaffRoutes from "./routes/StaffRoutes";
import OnboardingModal from "./tabs/gym/onboarding/OnboardingModal";
import { logout } from "./utils/helpers";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isInitialized = useSelector(isAppInitialized);
  const isInProgress = useSelector(isGymInfoInProgress);
  const isGymListInProgress = useSelector(isGymListApiInProgress);
  const userRole = useSelector(getUserRoleForActiveGym);

  useEffect(() => {
    if (localStorage.getItem("token") && !isInitialized) {
      dispatch(initialize());
    }
  }, [dispatch, isInitialized, navigate]);

  if (isInProgress || isGymListInProgress) {
    // if (isInProgress || isGymListInProgress) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }
  return (
    <>
      <ToastContainer />
      <AdminRoutes />
    </>
  );
}

export default App;

export const AuthRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasGyms = useSelector(hasGymsAdded);
  const userRole = useSelector(getUserRoleForActiveGym);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      logout();
      navigate("/login");
      return;
    }
  }, [dispatch, navigate]);

  // useEffect(() => {
  //   if (activeGymId === "0000-0000-0000-0000") {
  //     navigate("/onboarding");
  //   }
  // }, [activeGymId, navigate]);

  // console.log("line 74 ", userRole, hasGyms);

  // if (!userRole && hasGyms) {
  //   return (
  //     <div className="flex justify-center items-center h-full">
  //       <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
  //     </div>
  //   );
  // }

  return (
    <>
      <OnboardingModal />
      <HorizontalLayout>
        <Outlet />
      </HorizontalLayout>
    </>
  );
};

const FullScreenLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeGymId = useSelector(getActiveGymId);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
      return;
    }
  }, [dispatch, navigate]);

  return <Outlet />;
};

const getRoutes = (role) => {
  if (role === 2) {
    return <AdminRoutes />;
  }
  return <StaffRoutes />;
};
