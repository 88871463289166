import { useCallback, useEffect, useState } from "react";

const useFetchAsync = (asyncFn, executeImmediately = false) => {
  const [inProgress, setInProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const handleExecution = useCallback(
    async (...args) => {
      try {
        setInProgress(true);
        setIsSuccess(false);
        const res = await asyncFn(...args);
        setData(res);
        setIsSuccess(true);
      } catch (err) {
        setError(err);
      } finally {
        setInProgress(false);
      }
    },
    [asyncFn]
  );

  useEffect(() => {
    if (executeImmediately) {
      handleExecution();
    }
  }, [executeImmediately, handleExecution]);

  return { execute: handleExecution, inProgress, data, error, isSuccess };
};

export default useFetchAsync;
